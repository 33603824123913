import React, { useRef } from "react";
import * as PR from "../../prime-modules/index";
import { esimlinksLogo } from "../../assets/images/index";
import * as Yup from "yup";
import { useFormik } from "formik";
import {  useNavigate, Link } from "react-router-dom";
import { forgotPassword } from "../../services/api";
import AffiliateFooter from "../layout/affiliate-footer";
import { useDispatch } from "react-redux";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);

  const formInitialValues = {
    email: '',
  };

  const validationSchema = () => {
    return Yup.object().shape({
      email: Yup.string().trim()
      .email('Invalid email')
      .required('Email is required'),
    });
  }

  const handleSubmit = (formData) => {

        const getVerifyResponse = (response) => {
          if (response.result === "SUCCESS") {
            formik.resetForm();
            toast.current.show({ life: 10000, severity: 'success', summary: 'Success', detail: 'Your email address has been verified successfully.' });
            setTimeout(() => {
              navigate('/login');
            }, 2000);
          } else if (response.result === "FAILED" && response.error) {
            const error = response.error;
            toast.current.show({
              severity: error.severity,
              summary: "Error",
              detail: error.errorMsg ? error.errorMsg : error.summary,
            });
          }
        };
        forgotPassword(`forgotpassword?email=${formData.email}`, dispatch, getVerifyResponse);
  }

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <section className="admin-login-section">
        <div className="grid grid-nogutter">
          <div className="col-12">
            <div className="login-wrapper">
            <div className="login-header flex align-items-center justify-content-between">
                <PR.Image
                  src={esimlinksLogo}
                  alt="Logo"
                  className="logo-img"
                />
                <h1>Forgot password?</h1>
              </div>
              <form autoComplete="off" onSubmit={formik.handleSubmit} className="email-verification-form">
                <div className="grid">
                    <div className="col-12">
                    <h5>No worries! Just enter your email and we'll send you a reset password link.</h5>
                                        <span className="p-input-icon-left w-12">
                                            <i className="pi pi-envelope" />
                                            <PR.InputText id="email" name="email" placeholder="Email" className="w-12"
                                                value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off"
                                            />
                                        </span>
                                        {formik.errors.email && formik.touched.email
                                            ? <div className="error-message">{formik.errors.email}</div>
                                            : ''
                                        }
                                    </div>

                  <div className="col-12 mt-3 navigate">
                    <PR.Button
                      label="Send reset link"
                      type="submit"
                      className="login-btn w-12"
                    />
                  </div>
                  <div className="col-12 text-center">
                    <p className="login-p">
                    New here? <Link to="/signup">Sign Up</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <AffiliateFooter/>
      <PR.Toast ref={toast} position="top-right" />
    </>
  );
};

export default ForgotPassword;
