import React, { useEffect, useState, useRef } from "react";
import * as PR from "../../prime-modules/index";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { esimlinksLogo } from "../../assets/images/index";
import { resetPassword } from "../../services/api";
import AffiliateFooter from "../layout/affiliate-footer";

const ResetPassword = () => {
    const dispatch = useDispatch();
    const toast = useRef(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const passwordResetUUID = queryParams.get('id');


    useEffect(() => {
        console.log(passwordResetUUID,"passwordResetUUID")
        if (!passwordResetUUID) {
            toast.current.show({ life: 10000, severity: 'error', summary: 'error', detail: 'Token is missing.' });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formInitialValues = {
        password: '',
        confirmPassword: ''
    };

    const validationSchema = () => {
        return Yup.object().shape({
            password: Yup.string().trim()
            .required("Password is required")
            .min(8, "Password should be between 8 and 20 characters with no space, atleast one character and one number")
            .max(20, "Password should be between 8 and 20 characters with no space, atleast one character and one number")
            .matches(/\d/, "Password should be between 8 and 20 characters with no space, atleast one character and one number")
            .matches(/[A-Za-z]/, "Password should be between 8 and 20 characters with no space, atleast one character and one number")
            .strict(true),
            confirmPassword: Yup.string().trim()
                .required("Confirm password is required")
                .when("password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "Passwords are not matching"
                )
            })
        });
    }

    const submitHandler = (formData) => {
        setDisableBtn(true);
        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                toast.current.show({ life: 10000, severity: 'success', summary: 'success', detail: 'Your password has been changed successfully.' });
                formik.resetForm();
                setTimeout(() => {
                    setDisableBtn(false);
                }, 3000);
            } else {
                const error = response.error;
                toast.current.show({ life: 10000, severity: 'error', summary: 'error', detail: error.errorMsg });
                setDisableBtn(false);
            }
        }
        const resetPasswordRequest = {
            passwordResetUUID: passwordResetUUID,
            password: formData.password
        }
        resetPassword(resetPasswordRequest, dispatch, getResponse)
    }
    
    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: validationSchema,
        onSubmit: submitHandler
    });
    return (
        <>
        <section className="admin-login-section">
          <div className="grid grid-nogutter">
            <div className="col-12">
              <div className="login-wrapper">
              <div className="login-header flex align-items-center justify-content-between">
                  <PR.Image
                    src={esimlinksLogo}
                    alt="Logo"
                    className="logo-img"
                  />
                  <h1>Reset Password</h1>
                </div>
                <form autoComplete="off" onSubmit={formik.handleSubmit} className="email-verification-form">
                  <div className="grid">
                    <div className="col-12 md:col-12">
                      <h5>Please create a new password that you don't use on any other site.</h5>
                        <PR.InputText
                          id="password"
                          name="password"
                          placeholder="Enter Password"
                          className="w-12"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
                      {formik.errors.password && formik.touched.password ? (
                        <div className="error-message">
                          {formik.errors.password}
                        </div>
                      ) : (
                        ""
                      )}

                        <PR.InputText
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Enter Confirm Password"
                          className="w-12 mt-3"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
      
                      {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                        <div className="error-message">
                          {formik.errors.confirmPassword}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
  
                    <div className="col-12 mt-3 navigate">
                      <PR.Button
                        label="Reset Password"
                        type="submit"
                        disabled={disableBtn}
                        className="login-btn w-12"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <p className="login-p">
                        Already have an account? <Link to="/login">Login</Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <AffiliateFooter/>
        <PR.Toast ref={toast} position="top-right" />
      </>
    );
};

export default ResetPassword;